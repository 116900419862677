@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(to bottom,
            transparent,
            rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

.fixed.z-40.w-full.h-full.left-0.top-0.bg-black.bg-opacity-50.duration-400 {
    @apply z-1000;
}

.loader {
    height: 2rem;
    aspect-ratio: 2.5;
    --_g: no-repeat radial-gradient(farthest-side,#e3e2e6 90%,#e3e2e6);
    background: var(--_g), var(--_g), var(--_g), var(--_g);
    background-size: 20% 50%;
    animation: l44 1s infinite linear alternate; 
  }
  @keyframes l44 {
    0%,
    5%    {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
    12.5% {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
    25%   {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
    37.5% {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 0   ,calc(3*100%/3) 50% }
    50%   {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 0   }
    62.5% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 100%,calc(3*100%/3) 0   }
    75%   {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 100%}
    87.5% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%}
    95%,
    100%  {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
}






@layer base {
    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
        @apply text-primary-900
    }

    p {
        @apply text-primary-800
    }

    h1 {
        font-size: 2.25rem;
    }
    h2 {
        font-size: 1.875rem;
    }
    h3 {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1.25rem;
    }
    h5 {
        font-size: 1.125rem;
    }
    h6 {
        font-size: 1rem;
    }
    p {
        font-size: 1rem;
    }

    .k-tabbar-link-active > span > span:not(.k-tabbar-link-icon) {
        @apply text-primary-800;
    }

    label {
        @apply text-primary-800;
    }
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}